@import 'common/_variables';
p{
    margin: 0;
}
.registration {
    padding: 108px 0 80px;

    @media (min-width:768px) {
        padding: 128px 0 80px;
    }
    
    @media (min-width:992px) {
        padding: 160px 0 80px;
    }

    flex-grow: 1;
    .container{
        @media (max-width:360px) {
            max-width: 1822px;
        }
    }


    &__breadcrumbs {
        display: flex;
        align-items: center;
        span{
            display: inline-block;
            width: 4px;
            height: 8px;
            margin: 0 14px;
            background:  url(../../img/lk/span.svg)no-repeat center/cover;
        }
    }

    &__breadcrumbs-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #6B7B8E;
    }

    &__main-title {
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        color: #0C143B;
        margin: 12px 0 24px 0;
        @media (min-width:1200px) {
            font-size: 42px;
            line-height: 48px;
            margin: 12px 0 32px 0;
        }
    }


    &__content {
        margin-top: 25px;
        @media (min-width:768px) {
            margin-top: 0;
        }
    }

    &__content-wrapper {
        border: 1.5px solid #F2F4F8;
        padding: 20px 12px;
        @media (min-width:768px) {
            padding: 24px;   
        }
    }

    &__content-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #0C143B;
        margin-bottom: 20px;
        @media (min-width:1200px) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 32px;
        }
    }
    &__form{
        display: grid;
        row-gap: 12px;
    }
    .input-label{
        position: absolute;
        left: 12px;
        top: 13px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #6B7B8E;
        pointer-events: none;
        &:focus{
            display: none;
        }
        span{
            color: #FE334A;
        }
    }

    &__input {
        position: relative;
        &--more-info{
            display: flex;
            
            flex-direction: column;
            @media (min-width:1200px) {
                align-items: center;
                flex-direction: row;
                input{
                    margin-right: 20px;
                }
            }
            
        }

        .error{
            border: 1px solid red;
        }
        input{
            background: #F2F4F8;
            border-radius: 6px;
            width: 100%;
            max-width: 428px;
            border: none;
            padding: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            transition: .5s opacity ease;
            &::placeholder{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #6B7B8E;
            }
            &:hover{
                opacity: .6;
            }
            &:focus + .input-label{
                display: none;
            }
            &:not(:placeholder-shown) + .input-label{
                display: none;
            }
            
        }
        textarea{
            background: #F2F4F8;
            border-radius: 6px;
            width: 100%;
            max-width: 428px;
            border: none;
            padding: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            transition: .5s opacity ease;
            resize: none;
            min-height: 140px;
            &::placeholder{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #6B7B8E;
            }
            &:hover{
                opacity: .6;
            }
            &:focus + .input-label{
                display: none  !important;
            }
            &:not(:placeholder-shown) + .input-label{
                display: none !important;
            }
            @media (min-width:768px) {
                min-height: 180px;
            }
        }
        button{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            padding: 12px 30px;
            background: #FE334A;
            border-radius: 4px;
            border: none;
            margin-top: 20px;
            cursor: pointer;
            &:disabled {
                opacity: 0.7;
            }
        }
        
    }
    &__input-more-info{
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #6B7B8E;
        margin-top: 8px;
        @media (min-width:1200px) {
            margin-top: 0;
            font-size: 16px;
            line-height: 22px;
        }
        
    }

    &__more {
        max-width: 428px;
        margin-top: 4px;
    }

    &__footer {
        position: relative;
        font-size: 16px;
        line-height: 22px;
        color: #6B7B8E;
        &-checkbox {
            position: absolute;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background: #F2F4F8;
            border-radius: 6px;

            input {display: none;}

            &-icon:before {
                display: none;
                content: url("../../img/registration/check.svg");

                input:checked + & {display: block;}
            }
        }

        &-text {
            padding-left: 36px;

            & a {
                color: #13A8FC;
                font-weight: 600;
            }
        }

    }

    // .registration__input-phone::placeholder {
    //     font-size: 0;
    // }

}

