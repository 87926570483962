/* Blocks */
p {
	margin: 0;
}

.registration {
	padding: 108px 0 80px;
	flex-grow: 1;
}

@media (min-width: 768px) {
	.registration {
		padding: 128px 0 80px;
	}
}

@media (min-width: 992px) {
	.registration {
		padding: 160px 0 80px;
	}
}

@media (max-width: 360px) {
	.registration .container {
		max-width: 1822px;
	}
}

.registration__breadcrumbs {
	display: flex;
	align-items: center;
}

.registration__breadcrumbs span {
	display: inline-block;
	width: 4px;
	height: 8px;
	margin: 0 14px;
	background: url(../../img/lk/span.svg) no-repeat center/cover;
}

.registration__breadcrumbs-item {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.registration__main-title {
	font-weight: 600;
	font-size: 36px;
	line-height: 48px;
	color: #0C143B;
	margin: 12px 0 24px 0;
}

@media (min-width: 1200px) {
	.registration__main-title {
		font-size: 42px;
		line-height: 48px;
		margin: 12px 0 32px 0;
	}
}

.registration__content {
	margin-top: 25px;
}

@media (min-width: 768px) {
	.registration__content {
		margin-top: 0;
	}
}

.registration__content-wrapper {
	border: 1.5px solid #F2F4F8;
	padding: 20px 12px;
}

@media (min-width: 768px) {
	.registration__content-wrapper {
		padding: 24px;
	}
}

.registration__content-title {
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: #0C143B;
	margin-bottom: 20px;
}

@media (min-width: 1200px) {
	.registration__content-title {
		font-size: 32px;
		line-height: 40px;
		margin-bottom: 32px;
	}
}

.registration__form {
	display: grid;
	row-gap: 12px;
}

.registration .input-label {
	position: absolute;
	left: 12px;
	top: 13px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
	pointer-events: none;
}

.registration .input-label:focus {
	display: none;
}

.registration .input-label span {
	color: #FE334A;
}

.registration__input {
	position: relative;
}

.registration__input--more-info {
	display: flex;
	flex-direction: column;
}

@media (min-width: 1200px) {
	.registration__input--more-info {
		align-items: center;
		flex-direction: row;
	}
	.registration__input--more-info input {
		margin-right: 20px;
	}
}

.registration__input .error {
	border: 1px solid red;
}

.registration__input input {
	background: #F2F4F8;
	border-radius: 6px;
	width: 100%;
	max-width: 428px;
	border: none;
	padding: 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	transition: .5s opacity ease;
}

.registration__input input::placeholder {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.registration__input input:hover {
	opacity: .6;
}

.registration__input input:focus + .input-label {
	display: none;
}

.registration__input input:not(:placeholder-shown) + .input-label {
	display: none;
}

.registration__input textarea {
	background: #F2F4F8;
	border-radius: 6px;
	width: 100%;
	max-width: 428px;
	border: none;
	padding: 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	transition: .5s opacity ease;
	resize: none;
	min-height: 140px;
}

.registration__input textarea::placeholder {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.registration__input textarea:hover {
	opacity: .6;
}

.registration__input textarea:focus + .input-label {
	display: none  !important;
}

.registration__input textarea:not(:placeholder-shown) + .input-label {
	display: none !important;
}

@media (min-width: 768px) {
	.registration__input textarea {
		min-height: 180px;
	}
}

.registration__input button {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #FFFFFF;
	padding: 12px 30px;
	background: #FE334A;
	border-radius: 4px;
	border: none;
	margin-top: 20px;
	cursor: pointer;
}

.registration__input button:disabled {
	opacity: 0.7;
}

.registration__input-more-info {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #6B7B8E;
	margin-top: 8px;
}

@media (min-width: 1200px) {
	.registration__input-more-info {
		margin-top: 0;
		font-size: 16px;
		line-height: 22px;
	}
}

.registration__more {
	max-width: 428px;
	margin-top: 4px;
}

.registration__footer {
	position: relative;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.registration__footer-checkbox {
	position: absolute;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	background: #F2F4F8;
	border-radius: 6px;
}

.registration__footer-checkbox input {
	display: none;
}

.registration__footer-checkbox-icon:before {
	display: none;
	content: url("../../img/registration/check.svg");
}

input:checked + .registration__footer-checkbox-icon:before {
	display: block;
}

.registration__footer-text {
	padding-left: 36px;
}

.registration__footer-text a {
	color: #13A8FC;
	font-weight: 600;
}
